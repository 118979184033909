<template>
  <div style="width: 100%" v-loading="loading" class="balance-detail-detail">
    <!-- 充值需显示 -->
    <el-form label-width="100px" v-if="$props.row.orderFlowType == 1">
      <el-form-item label="充值驾校">
        <el-input disabled :value="$store.state.user.drvSchoolForShort"></el-input>
      </el-form-item>
      <el-form-item label="充值金额">
        <el-input disabled :value="tableData.orderFlowAmount">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="业务编号">
        <el-input disabled :value="tableData.businessNo"></el-input>
      </el-form-item>
      <el-form-item label="交易流水号">
        <el-input disabled :value="tableData.payNo"></el-input>
      </el-form-item>
      <el-form-item label="付款人">
        <el-input disabled :value="tableData.payer"></el-input>
      </el-form-item>
      <el-form-item label="付款开户行">
        <el-input disabled :value="tableData.bank"></el-input>
      </el-form-item>
      <el-form-item label="充值凭证">
        <el-image
          style="max-width: 100%"
          fit="contain"
          :src="$dict.SETTING.IMG_URL + tableData.voucherPath"
          :preview-src-list="[$dict.SETTING.IMG_URL + tableData.voucherPath]">
        </el-image>
      </el-form-item>
    </el-form>
    <!-- 结算需显示 -->
    <el-descriptions :column="2" border v-else>
      <el-descriptions-item label="姓名" label-class-name="my-label">{{ tableData.studentName }}</el-descriptions-item>
      <!-- <el-descriptions-item  label="会员服务单号">{{ tableData.policyNo }}</el-descriptions-item> -->
      <el-descriptions-item label="身份证号" label-class-name="my-label">{{ tableData.studentIdCard }}</el-descriptions-item>
      <el-descriptions-item label="会员服务金额">
        {{ tableData.orderFlowAmount }}
      </el-descriptions-item>
      <el-descriptions-item label="驾照类型">{{ tableData.vehicleType }}</el-descriptions-item>
      <el-descriptions-item label="订单时间">{{ $utils.formatDateFull(tableData.creationTime) }}</el-descriptions-item>
    </el-descriptions>

    <div class="admin-dialog-btns">
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        ">
        知道了
      </el-button>
    </div>
  </div>
</template>

<script>
import {schBalanceGetById} from '@/api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      tableData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      schBalanceGetById({orderFlowId: this.$props.row.orderFlowId}).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.balance-detail-detail {
  .el-input {
    width: 380px;
  }
  .my-label {
    width: 120px;
  }
  .admin-dialog-btns {
    margin-top: 20px;
  }
}
</style>
